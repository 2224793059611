.pm-lineup {
	$c: &;

	position: relative;

	padding-bottom: 30px;


	&__lineup-list {
		position: absolute;
		top: -5px;
		left: 10px;

		z-index: 2;


		// add overlay shadow above lineup list when active
		.m-modal .c-overlay.-c-active ~ .m-modal__inner & {
			&:after {
				content: '';

				position: absolute;
				top: 0;
				right: 0;
				left: 0;

				height: 4px;

				background: rgba(#291D12, 0.8);

				z-index: 2;
			}
		}
	}

	&__team-details {
		display: flex;
		flex-flow: row wrap;

		padding: 40px 420px 50px 15px;
	}

	&__chakra {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	&__chakra-text {
		margin-bottom: 5px;
		padding-right: 190px;

		font-size: 0.65rem;
		line-height: 1;
		text-align: right;
		text-shadow: 1px 1px 1px #000;

		color: #675b50;//69513d;


		b {
			display: block;

			font-size: 0.8rem;
			text-transform: uppercase;

			color: #75685b;//7b614a;
		}
	}

	&__lineup {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;

		padding: 10px 5px 20px 15px;



		&.-status-selecting {

			.pm-lineup-card:not(.-status-selectable) {
				opacity: 0.2;
			}
		}
	}

	&__extra {
		padding: 0 5px 20px 15px;


		// component
		.c-page-desc {
			margin-left: -25px;

			text-align: center;
		}
	}


	&__extra-lineup {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-content: flex-start;

		padding-top: 5px;


		// component
		.c-button {
			width: 72px;
		}
	}


	&__combos {
		.c-item {

		}
	}

	&__sort {

	}

	&__filter {

	}


	// module
	.m-button-list {
		display: flex;


		&__title {
			width: 90px;
			min-width: 90px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}


		&.-c-list-padding {
			padding: 8px 80px 8px 0;
		}
	}



	@include for-phone-landscape-up {
		&__lineup-list {
			left: 100px;
		}

		&__team-details {
			padding-left: 100px;
		}

		&__chakra {
			right: 50px;
		}

		&__lineup {
			padding-right: 10px;
			padding-left: 100px;
		}

		&__extra {
			padding-right: 10px;
			padding-left: 100px;
		}


		// module
		.m-button-list {
			display: flex;


			&__title {
				width: 105px;
				min-width: 105px;
			}


			&.-c-list-padding {
				padding: 8px 70px;
			}
		}
	}
}





/**
 * Lineup card container.
 */
.pm-lineup-card {
	$c: &;

	flex: 0 1 auto;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;

	margin: 0 15px 20px 0;


	&__options {
		margin: 2px 0 0 8px;


		// component
		.c-button {
			$button: '.c-button';


			// only show icon on yes button
			&.-color-no {
				justify-content: center;


				#{$button}__icon {
					display: none;
				}
			}
		}
	}


	// module
	.m-card-container {

		// display full-width equipment even if empty
		&__items {
			min-width: 24px;
		}
	}


	&.-size-s {
		#{$c}__options {
			margin: 2px 0 0 -10px;
		}
	}
}





/**
 * Chakra container.
 */
.pm-lineup-chakra {
	display: flex;
	flex-flow: row nowrap;


	&__content {
		flex: 1 0 auto;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-end;
	}

	&__button {
		flex: 0 0 180px;

		margin-left: 8px;


		// component
		&.c-button {
			width: 180px;
			min-width: 180px;
			height: 55px;

			z-index: 2;
		}
	}
}


/**
 * Chakra box content.
 */
.pm-lineup-chakra-box {
	position: relative;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	min-width: 145px;
	height: 55px;
	padding-bottom: 12px;

	border: 1px solid #34261b;
	border-radius: 3px;

	text-shadow: 1px 1px 1px #000;

	background-color: #211507;


	&:before {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border-style: solid;
		border-color: #000;
		border-width: 3px 1px 1px 3px;
		border-radius: 3px;
	}


	i {
		flex: 0 0 40px;

		width: 40px;

		font-size: 20px;
		text-align: center;

		color: #888;
	}


	&__nr {
		flex: 1 0 auto;

		font-size: 1rem;
		text-align: right;

		color: #999;


		b {
			font-size: 1.2rem;

			color: #ddd;
		}
	}

	&__free {
		position: absolute;
		right: 1px;
		bottom: 1px;
		left: 3px;

		height: 15px;
		padding: 1px 6px;

		font-size: 0.6rem;
		text-align: right;
		text-transform: uppercase;

		color: #555;

		background: linear-gradient(to right, #221507, #000);


		b {
			color: #888;
		}
	}
}





/**
 * Team details container.
 */
.pm-lineup-team-detail {
	position: relative;

	margin: 0 10px;

	display: flex;
	flex-flow: row nowrap;

	text-shadow: 1px 1px 1px #000;

	color: #735e45;


	&__info {
		text-align: right;


		span {
			display: block;

			text-transform: uppercase;

			font-size: 0.6rem;
		}

		b {
			position: relative;
			top: -4px;

			display: block;

			font-size: 0.8rem;
			text-align: right;

			color: #a58764;
		}
	}

	i {
		flex: 0 0 30px;

		width: 30px;
		padding-left: 4px;

		font-size: 16px;
	}
}
