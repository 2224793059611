.pm-friends {
	position: relative;

	display: flex;
	flex-flow: row nowrap;

	min-height: 500px;


	&__list {
		position: relative;

		flex: 0 0 445px;

		width: 445px;
	}

	&__search {
		flex: 0 1 auto;

		padding: 20px 5px 20px 30px;
	}
}





/**
 * Friends list column container.
 */
.pm-friends-list {
	position: relative;

	width: 100%;
	height: 450px;
	margin-left: -10px;


	&__row {
		display: flex;

		margin-bottom: 10px;
	}

	&__user {
		flex: 1 1 auto;


		// component
		&.c-user-box {
			width: 200px;
		}
	}

	&__challenge {
		flex: 0 1 100px;


		// component
		.c-button {
			$button: '.c-button';

			width: 90px;
			margin: 0 0 2px 4px;


			span {
				margin-left: auto;
				padding-right: 14px;
			}


			#{$button}__icon {
				display: block;
				margin-right: -5px;
			}
		}
	}

	&__login {
		min-width: 100px;

		border-radius: 0 3px 3px 0;

		text-align: center;
		white-space: nowrap;

		background-color: #231909;


		span {
			display: block;
			padding: 2px 6px 2px 6px;

			font-size: 0.6rem;
			line-height: 1.4;

			color: #CA7777;
		}


		&.-c-online {
			span {
				color: #77CA7E;
			}
		}
	}

	&__options {
		flex: 1 0 80px;

		display: flex;
		justify-content: flex-end;


		// component
		.c-button {
			margin-right: 5px;
		}
	}


	// module
	.m-scroll-list {

		// additional padding because of scroll list fading shadow
		&__container {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

}





/**
 * Friends filter section.
 */
.pm-friends-filter {
	position: relative;
	top: -10px;
}





/**
 * Background for friends list.
 */
.pm-friends-list-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 450px;

	background: rgba(#231909, 0.5);



	@include for-phone-landscape-up {
		left: 80px;
	}
}





/**
 * Search teams column container.
 */
.pm-friends-search {
	margin-top: 20px;


	input[type=text] {
		min-width: 200px;
	}

	// component
	.c-button {
		margin: 10px 0 5px 0;
	}
}