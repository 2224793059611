.pm-achievements {
	position: relative;

	min-height: 500px;

	&__list {
		margin: 10px 0 20px 0;
	}


	// component
	.c-page-desc {
		margin-right: 400px;
	}
}





/**
 * Achievements lists.
 */
.pm-achievements-list {
	background-color: #34250e;


	&__inner {
		display: flex;

		padding: 5px 10px 10px 5px;
	}


	// component
	.c-achievement {
		flex: 0 0 180px;

		width: 180px;
		min-width: 180px;

		margin: 4px;


		// add right margin to last element, because scroll bar cuts off invisible padding
		&:last-child {
			&:after {
				content: '';

				position: absolute;
				top: 0;
				left: 100%;

				width: 10px;
				height: 1px;
			}
		}
	}
}





/**
 * Achievements summary section.
 */
.pm-achievements-summary {
	position: absolute;
	top: -25px;
	right: -100px;

	&__container {
		position: relative;

		display: flex;
		align-items: center;

		min-width: 450px;
		height: 100px;

		padding: 20px;

		border: 2px solid #5a4630;
		border-top: 0;
		border-radius: 0 0 3px 3px;

		background-color: #2b1e10;

		&:before {
			content: '';

			position: absolute;
			top: 5px;
			left: -3px;
			right: -3px;
			bottom: -4px;

			border: 2px solid #211709;
			border-width: 0 1px 2px 1px;
			border-radius: 0 0 5px 5px;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			left: 0;

			height: 4px;

			border: 1px solid #21170a;

			background-color: #3e2e1d;
		}
	}

	&__level {
		flex: 1 1 auto;

		padding: 10px;


		// module
		.m-exp-container {
			width: 100%;


			&__bar {
				border-color: #423527;
			}
		}

		// component
		.c-exp {
			width: 100%;
		}
	}

	&__view-all {
		flex: 0 0 auto;

		// component
		.c-button {

		}
	}

	&__levelup {
		position: absolute;
		right: 21px;
		top: 80px;


		// component
		.c-button {

		}
	}
}