.pm-battle {
	height: 100%;
	min-height: 747px;

	padding-top: 0.01em; // fix for margin pushing, can also use flex or overflow

	overflow: hidden; // sets bounds for animations


	&__presentation {

		// page
		.pm-battle-side {
			margin-top: 38px;
		}
	}

	&__summary {

		/*
		.pm-battle-matchup {
			&__content {
				margin-top: 40px;
			}
		}
		*/
	}

	&__cover {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: none;
		width: 100%;
		height: 100%;

		overflow: hidden;

		opacity: 0;

		z-index: -30;


		&.-c-active {
			display: block;

			opacity: 1;

			z-index: 30;


			.pm-battle-matchup {
				animation: 0.5s bounceIn both;

				opacity: 1;
			}
		}
	}

	&__screen {
		position: relative;

		margin: 30px 0 50px 20px;
	}

	&__sides {
		display: flex;
		flex-flow: row nowrap;
	}

	&__sides-full {
		position: relative;

		flex: 0 0 auto;

		width: 100%;


		&.-battle-side-right {
			display: flex;
			flex-flow: column nowrap;
			align-items: flex-end;
		}
	}

	&__sides-cell {
		position: relative;

		flex: 0 0 auto;

		width: 50%;


		&.-battle-side-right {
			display: flex;
			flex-flow: column nowrap;
			align-items: flex-end;
		}
	}



	@include for-phone-landscape-up {
		&__screen {
			margin: 30px 20px 50px 100px;
		}
	}

	@include for-desktop-up {
		&__screen {
			margin-right: 60px;
		}
	}
}





/**
 * Matchup section.
 */
.pm-battle-matchup {
	position: relative;

	width: 100%;
	min-height: 300px;
	margin-top: 118px;
	padding-top: 0.01em; // fix for margin pushing, can also use flex or overflow

	background: rgba(#291D12, 0.95);

	opacity: 0;

	will-change: transform, opacity;


	&__inner {
		position: relative;

		margin: 0;

		height: 100%;
		min-height: inherit;


		&:before,
		&:after {
			content: '';

			position: absolute;
			top: -45px;
			right: 0;
			left: 0;

			width: 675px;
			height: 92px;
			margin: 0 auto;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/divider_area_1.png') center 0 no-repeat;
		}

		&:after {
			top: auto;
			bottom: -45px;
		}
	}


	&__content {
		display: flex;
		flex-flow: row nowrap;

		width: 100%;
		padding: 40px 0 100px 0;
	}

	&__content-side {
		flex: 1 0 auto;

		width: 0; // force equal width
	}

	&__content-center {
		flex: 0 0 150px;

		display: flex;
		justify-content: center;

		width: 150px;
	}

	&__vs {
		position: relative;

		font-size: 5rem;
		font-weight: 600;

		color: #F19835;


		&.-status-loss {
			color: #ff0000;
		}

		&.-status-win {
			color: #3BCA3B;
		}
	}

	&__header {
		margin-bottom: 25px;


		.-side-right & {
			margin-right: 30px;
		}
	}

	&__title {
		padding: 0 5px;

		font-size: 1.4rem;
		letter-spacing: -1px;

		color: #E4A06F;


		.-side-left & {
			text-align: right;
		}
	}

	&__desc {
		margin-top: -3px;
		padding: 0 6px;

		font-size: 0.65rem;
		line-height: 1.2;
		text-transform: uppercase;
		text-shadow: 2px 2px 0 #000;

		color: #A07B4B;


		b {
			color: #C7985B;
		}


		.-side-left & {
			text-align: right;
		}
	}

	&__buttons {
		position: absolute;
		right: 30px;
		bottom: 0;
		left: 30px;

		height: 110px;

		z-index: 1;


		&.-c-faded {
			opacity: 0.2;
		}
	}


	// visible by .pm-battle-cover--active



	@include for-phone-landscape-up {
		&__inner {
			margin-left: 50px;
		}

		&__buttons {
			right: 90px;
			left: 90px;
		}
	}

	@include for-desktop-up {
		&__inner {
			margin-left: 0;
		}

		&__buttons {
			right: 90px;
			left: 90px;
		}
	}
}


/**
 *
 */
.pm-battle-buttons {

	&__start {
		position: absolute;
		top: -10px;
		left: 50%;

		transform: translateX(-50%);


		// component
		&.c-button {

		}
	}

	&__finish {
		position: absolute;
		top: 0;
		left: 50%;

		transform: translateX(-50%);


		// component
		&.c-button {
			min-width: 110px;
		}
	}

	&__rewatch {
		position: absolute;
		top: 30px;
		left: 0;
	}

	&__skip {
		position: absolute;
		top: 30px;
		left: 0;
	}

	&__retry {
		position: absolute;
		top: 30px;
		right: 0;
	}

	&__multi {
		position: absolute;
		top: 30px;
		right: 130px;
	}



	@include for-desktop-up {
		&__multi {
			right: 200px;
		}
	}
}


/**
 *
 */
.pm-battle-side {
	$c: &;

	display: flex;
	flex-flow: column nowrap;

	padding-bottom: 20px;


	&__outer {
		display: flex;

		margin-bottom: 10px;


		// position elements on right
		.-side-left & {
			justify-content: flex-end;
		}
	}

	&__team {
		max-width: 300px;
		min-width: 0;
	}

	&__treasures {

	}

	&__avatar {
		margin: 0 5px;


		// position on right
		.-side-left & {
			order: 1;
		}
	}

	&__area {
		margin-top: 12px;
		padding: 0 6px;

		font-size: 0.65em;
		text-transform: uppercase;
		text-shadow: 2px 2px 0 #000;

		color: #A07B4B;
	}

	&__details {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;


		// position elements on right
		.-side-left & {
			justify-content: flex-end;
		}
	}

	&__rating {
		padding: 0 3px;

		font-size: 0.8rem;
		text-shadow: 1px 1px 0 #000;

		color: #E4A06F;
	}

	&__rank {
		margin: 0 3px 0 0;


		// component
		&.c-rank {
			flex: 0 0 auto;
		}


		// position first
		.-side-right & {
			margin: 0 0 0 3px;

			order: -1;
		}
	}

	&__name {
		max-width: 100%;
		margin-top: -6px;
		padding: 0 5px;

		font-size: 2rem;
		font-weight: 700;
		letter-spacing: -2px;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #E4A06F;

		overflow: hidden;


		a {
			color: inherit;
		}


		// position elements on right
		.-side-left & {
			text-align: right;
		}
	}

	&__mission {
		margin-top: -4px;

		font-size: 1.4rem;
	}

	&__resources {
		display: flex;
		flex-flow: row wrap;

		margin: 10px 0 0 0;


		// component
		.c-resource {
			flex: 0 0 auto;

			margin: 0 2px;
			padding-left: 4px;
		}


		// position elements on right
		.-side-left & {
			justify-content: flex-end;
		}
	}


	// position elements on right
	.-side-left & {
		justify-content: flex-end;
	}
}


/**
 * Treasure section in summary.
 */
.pm-battle-treasures {
	position: relative;

	width: 100%;
	margin: 0 5px;


	&__desc {
		display: flex;

		width: 250px;
		margin: -10px 0 5px 0;

		border-bottom: 1px solid #5d4a33;

		line-height: 1;


		span {
			flex: 0 0 auto;

			padding: 0 10px;

			font-size: 0.6rem;
			text-align: right;

			color: #A07B4B;


			&:first-child {
				flex-grow: 1;

				border-right: 1px solid #5d4a33;

				text-align: left;
			}
		}
	}

	&__drop {
		position: relative;

		display: flex;
		flex-flow: row wrap;

		margin: 0 0 5px 0;

		z-index: 1;


		// component
		.c-item {
			&.-size-l {
				width: 180px;
			}
		}


		&.-c-multi {

			// component
			.c-item {
				margin: 0 5px 0 0;
			}
		}


		&.-status-shake {
			animation: 0.5s shake;
		}

		&.-status-success {
			animation: 1s tada;

			z-index: 2;


			// component
			.c-item {
				box-shadow: 0 0 15px 5px #ffbc00;
			}
		}

		&.-status-failed {
			filter: grayscale(1);

			transition: opacity 0.25s linear;

			opacity: 0.3;
		}

		&.-status-done {
			z-index: 2;


			// component
			.c-item {
				&__roll {
					transition: opacity 0.3s linear;

					opacity: 0.4;
				}
			}
		}
	}

	&__roll {
		position: relative;

		display: flex;
		align-items: center;

		padding-left: 25px;


		i {
			position: absolute;
			left: -7px;
			top: -11px;

			height: 40px;

			font-size: 34px;

			color: #F19835;

			z-index: 1;
		}
	}

	&__roll-nr {
		width: 50px;
		padding: 0 2px;

		text-align: center;

		color: #FFAC31;


		&.-status-failed {
			color: #CCA770;
		}

		&.-status-shake {
			animation: 0.5s shake;
		}

		&.-status-duplicate {
			&:before {
				content: '\e828';

				position: absolute;
				top: 0;
				right: -5px;

				font-family: 'nm';
				font-style: normal;

				color: #F73333;
			}
		}
	}
}


/**
 * Ninja unlocks section
 */
.pm-battle-ninja-unlocks {
	margin-top: -10px;

	padding: 0 5px;
}






/**
 * Round message section.
 */
.pm-battle-round {
	position: absolute;
	top: 120px;
	right: 0;
	left: 0;

	align-items: center;
	justify-content: center;

	display: flex;
	margin: 0 33px 0 6px;

	font-size: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: -2px;

	color: #F19835;

	opacity: 0;


	b {
		padding-left: 5px;

		font-size: 2.6rem;
	}


	&.-c-animate {
		animation-name: fadeInOut;
		animation-duration: 1s;
	}
}





/**
 * Dialogue section.
 */
.pm-battle-dialogue {
	position: absolute;
	top: 20px;
	right: 235px;
	left: 235px;

	display: flex;
	flex-flow: column nowrap;


	&__row {
		flex: 0 1 auto;

		position: relative;

		margin: 5px 5px 0 5px;
		padding: 10px 15px;

		border-radius: 3px;

		font-size: 0.75rem;
		line-height: 1;
		text-shadow: 1px 1px 1px #000;

		color: #FFDDBE;
		background-color: rgba(#291d12, 0.7);

		opacity: 0;


		b {
			color: #FDA446;
		}

		strong {
			color: #FBB717;
		}


		&:before {
			content: '';

			position: absolute;
			top: 6px;

			width: 0;
			height: 0;

			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
		}


		&.-c-visible {
			animation: 1s fadeIn forwards;
		}

		&.-side-0 {
			align-self: flex-start;

			margin-right: 20px;


			&:before {
				left: -12px;
				border-right: 12px solid rgba(#291d12, 0.7);
			}
		}

		&.-side-1 {
			align-self: flex-end;

			margin-left: 20px;


			&:before {
				right: -12px;
				border-left: 12px solid rgba(#291d12, 0.7);
			}
		}
	}



	@include for-desktop-up {
		&__row {
			&.-side-0 {
				margin-right: 80px;
			}

			&.-side-1 {
				margin-left: 80px;
			}
		}
	}
}





/**
 * Combatants section.
 */
.pm-battle-combatants {

	// page
	.pm-battle__sides-cell {
		height: 270px;
	}


	@include for-desktop-up {

		// page
		.pm-battle__sides-cell {
			&:hover {

				// page
				.pm-battle-ninja { // set z-index higher to enable equipment hovering
					z-index: 4;
				}
			}
		}
	}
}


.pm-battle-active-genjutsu {

}


.pm-battle-ninja {
	position: relative;


	// module
	.m-card-container {
		min-width: 212px; // in case of no equipment

		margin: 20px 0 0 5px;

		will-change: transform;

		z-index: 1;


		&.-c-animate {
			animation-name: slideInLeft;

			animation-duration: 0.5s;
		}


		.-battle-side-right & {
			margin-right: 10px;
			margin-left: 15px;


			&.-c-animate {
				animation-name: slideInRight;
			}
		}
	}

	// component
	.c-card {
		&__buki {
			transition: width 0.2s linear;


			&.-c-inactive {
				width: 40%;


				// component
				.c-bar {
					&__fill {
						display: none;
					}

					&__desc {
						&:before {
							color: #564F43;
						}


						span {
							color: #655D51;
						}
					}
				}
			}


			// component
			.c-bar {
				&__text-cur {
					transition: font 0.25s ease;


					&.-c-active {
						font-size: 1rem;
					}
				}
			}
		}
	}
}


.pm-battle-attack {
	$c: &;

	position: absolute;
	top: 70px;

	width: 271px;
	height: 220px;

	z-index: 3;

	will-change: opacity;


	&__blood {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		will-change: transform, opacity;


		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;

			z-index: 2;
		}


		&.-blood-1 {
			top: 55px;
			left: 35px;

			width: 193px;
			height: 156px;

			background: url('#{$gfx-path}/battle_blood_1.png') 0 0 no-repeat;


			#{$c}__dmg {
				top: 43px;
				left: 33px;
			}
		}

		&.-blood-3 {
			top: 0;
			left: 0;

			width: 271px;
			height: 220px;

			background: url('#{$gfx-path}/battle_blood_3.png') 0 0 no-repeat;


			#{$c}__dmg {
				top: 103px;
				left: 38px;
			}
		}


		&.-c-animate {
			animation: 0.75s bounceIn;
		}
	}

	&__dmg {
		position: absolute;
		top: 10px;
		left: 10px;

		color: #FF7F7B;

		z-index: 3;


		i {
			text-shadow: -1px -1px 0 #000,
						1px -1px 0 #000,
						-1px 1px 0 #000,
						1px 1px 0 #000,
						1px 2px 0 #000;

			font-size: 16px;
		}

		span {
			text-shadow: 1px 1px 0 #000,
						1px 2px 0 #000,
						-1px 0px 0 #000,
						0 1px 0 #000,
						-1px 2px 0 #000,
						-1px -1px 0 #000,
						1px -1px 0 #000,
						-1px 1px 0 #000;
		}


		.-c-visible {
			transition: opacity 0.3s linear;

			opacity: 1;
		}
	}

	&__dmg-original {
		position: absolute;
		top: -5px;
		left: 12px;

		width: 100px;

		font-size: 2.3rem;
		text-align: right;

		color: #FFCECD;

		opacity: 1;

		will-change: transform, opacity, font-size;


		span {
			padding: 0 2px;
		}


		&.-c-animate {
			animation: 0.75s bounceIn both;
		}

		&.-c-fade {
			top: -13px;
			left: 7px;

			font-size: 1rem;
			color: #FF7F7B;

			transition: all 1s ease;

			opacity: 1;
		}
	}

	&__dmg-modifier {
		position: absolute;
		top: -2px;
		left: 115px;

		width: 100px;

		opacity: 0;

		will-change: opacity;


		i {
			font-size: 16px;

			margin-left: -7px;
		}


		// helper
		.h-icon {
			&.-color-greyed {
				color: #bbb;
			}
		}
	}

	&__dmg-ele {
		position: absolute;
		top: 39px;
		left: 77px;

		width: 100px;

		opacity: 0;

		will-change: opacity;


		i {
			font-size: 16px;
		}

		span {
			margin-left: -4px;
			padding: 0 1px;

			font-size: 1rem;
		}
	}

	&__dmg-penalty {
		position: absolute;
		top: 19px;
		left: 107px;

		width: 100px;

		opacity: 0;

		will-change: opacity;


		i {
			font-size: 16px;
		}

		span {
			margin-left: -4px;
			padding: 0 1px;

			font-size: 1em;
		}
	}

	&__dmg-atk {
		position: absolute;
		top: 36px;
		left: 14px;

		width: 60px;

		text-align: right;

		opacity: 0;

		will-change: opacity;


		i {
			font-size: 16px;
		}

		span {
			margin-left: -4px;
			padding: 0 1px;

			font-size: 1em;
		}
	}

	&__dmg-final {
		position: absolute;
		top: 0;
		left: 0;

		width: 100px;

		font-size: 2rem;
		text-align: right;
		letter-spacing: -2px;

		color: #FFCECD;

		opacity: 0;

		will-change: opacity;
	}


	&.-c-fadeout {
		opacity: 0.3;

		transition: opacity 1s linear;
	}


	.-battle-side-left & {
		left: 110px;
	}

	.-battle-side-right & {
		right: 130px;
	}



	@include for-desktop-up {
		.-battle-side-left & {
			left: 130px;
		}

		.-battle-side-right & {
			right: 150px;
		}
	}
}





/**
 * Effects section.
 */
.pm-battle-effects {
	margin: 0 33px 0 6px;


	.pm-battle__sides-cell {
		height: 50px;
	}


	&__activations {
		position: relative;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;

		width: 300px;

		z-index: 3;


		.-battle-side-right & {
			justify-content: flex-end;
		}
	}


	// component
	.c-effect {
		flex: 0 0 auto;

		margin: 0 3px 1px 0;


		&.-c-animate {
			animation: 0.75s bounceIn both;
		}
	}
}





/**
 * Health section.
 */
.pm-battle-health {
	display: none;

	margin: 0 36px -4px 4px;


	.pm-battle__sides-full {
		transition: opacity 0.5s linear;

		opacity: 0;


		&.-c-visible {
			opacity: 1;
		}
	}


	&__bar {
		position: relative;

		min-width: 115px;

		overflow: visible;

		transition: width 0.5s ease;
		will-change: width;


		// modify text to grow straight
		.c-bar {
			height: 34px;


			&__fill {
				will-change: width;
			}

			&__text {
				align-items: flex-start;
			}

			&__text-cur {
				line-height: 1;

				transition: font-size 0.25s ease;
				will-change: font-size;


				&.-c-active {
					font-size: 1.2rem;
				}
			}

			&__text-max {
				line-height: 1.2;
			}
		}
	}


	&.-c-active {
		display: block;
	}

	&.-c-display-above {
		position: relative;

		z-index: 31;

		opacity: 1;
	}
}





/**
 * Genjutsu section.
 */
.pm-battle-genjutsu {
	margin: 0 36px 0 4px;


	.pm-battle__sides-cell {
		height: 100px;

		transition: opacity 0.5s linear;

		opacity: 0;


		&.-battle-side-left {
			padding-right: 20px;
		}

		&.-battle-side-right {
			padding-left: 20px;
		}

		&.-c-visible {
			opacity: 1;
		}
	}


	&__bar {
		min-width: 115px;
		overflow: visible;

		transition: width 0.5s ease;

		will-change: width;


		// modify text to grow straight
		.c-bar {
			height: 26px;


			&__fill {
				will-change: width;
			}

			&__text {
				align-items: flex-end;

				bottom: -1px;

				z-index: 2;
			}

			&__text-cur {
				line-height: 1;

				transition: font-size 0.25s ease;
				will-change: font-size;


				&.-c-active {
					font-size: 1rem;
				}
			}

			&__text-max {
				line-height: 1.2;
			}
		}
	}

	&__activations {
		position: absolute;
		top: 39px;
		left: 6px;

		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;

		width: 320px;

		z-index: 3;


		.-battle-side-right & {
			right: 6px;
			left: auto;

			align-items: flex-end;
		}
	}


	&.-c-margin-bottom {
		margin-bottom: 30px;
	}


	// component
	.c-effect {
		display: none;

		margin-bottom: 1px;

		transition: opacity 1s linear;

		opacity: 1;

		will-change: transform, opacity;


		&__text {
			max-width: 238px;
		}


		&.-c-animate {
			display: flex;

			animation: 1s tada;
		}

		&.-c-absorbed {
			opacity: 0.5;
		}
	}
}





/**
 * Lineups section.
 */
.pm-battle-lineups {
	margin: 0 33px 0 6px;


	.pm-battle__sides-cell {
		min-height: 187px;
	}


	&__lineup {
		position: relative;

		z-index: 2;
	}
}





/**
 * Control panel section.
 */
.pm-battle-control {
	$c: &;

	position: absolute;
	top: 540px;
	right: 0;
	left: 0;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;

	margin: 0 33px 0 6px;


	&__panel,
	&__options {
		display: flex;
		flex-flow: row nowrap;

		padding: 5px;

		border-radius: 4px;

		z-index: 5;
	}

	&__panel {
		background-color: rgba(#000, 0.3);


		#{$c}__icon {
			margin: 0 5px;
		}
	}

	&__options {
		margin: 10px 0 0 0;

		background-color: rgba(#000, 0.2);


		#{$c}__icon {
			font-size: 1.4rem;


			&.-c-animations {
				font-size: 1.2rem;
			}
		}
	}

	&__icon {
		flex: 0 0 35px;

		display: flex;
		align-items: center;
		justify-content: center;

		height: 35px;
		width: 35px;
		padding: 2px;

		font-size: 1.8rem;
		text-transform: uppercase;
		text-shadow: 2px 2px 0 #000,
					-1px -1px 0 #000;

		color: #8A6338;

		cursor: pointer;


		&:hover {
			color: #F19835;
		}


		&.-c-play {
			color: #8A6338;


			&:before {
				content: '\e84f';
			}

			&:hover {
				color: #F19835;
			}


			&.-c-paused {
				color: #F19835;

				animation: blink 1s step-start 0s infinite;


				&:before {
					content: '\e84b';
				}


				&:hover {
					color: #FF8600;
				}
			}

			&.-c-inuse {
				opacity: 0.25;


				&:hover {
					color: #8A6338;
				}
			}
		}


		&.-c-active {
			color: #F19835;
		}
	}


	&.-c-inuse {
		opacity: 0.25;


		&__icon {
			&:hover {
				color: #8A6338;
			}
		}
	}
}





/**
 * Play button section
 *      If battle is paused for a specific reason (tutorial).
 */
.pm-battle-play {
	position: absolute;
	top: 220px;
	right: 0;
	left: 0;

	display: none;
	margin: 0 auto;

	text-align: center;


	i {
		position: relative;

		padding: 10px;

		font-size: 70px;

		color: #F19835;

		cursor: pointer;

		z-index: 10;
	}


	&.-c-paused {
		display: block;


		i {
			animation: blink 1s step-end 0s infinite;
		}
	}
}





/**
 * Options list section.
 */
.pm-battle-options {
	position: absolute;
	bottom: 0;
	left: 20px;

	z-index: 31;


	// module
	&.m-lineup-list {
		border-bottom: 0;
	}



	@include for-phone-landscape-up {
		left: 100px;
	}
}





/**
 * Battle log section.
 */
.pm-battle-log {
	position: relative;

	display: none;
	height: 600px;
	margin: 30px 20px 50px 20px;
	padding: 10px;

	border: 2px solid #4A3722;
	border-radius: 5px;

	background: linear-gradient(#000, #291D12);

	z-index: 31;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -4px;
		right: -3px;
		bottom: -4px;

		border-style: solid;
		border-color: #000;
		border-width: 1px 2px 2px 1px;
		border-radius: 4px;
	}


	&__outer {
		position: relative;

		height: 100%;
	}

	&__inner {
		text-shadow: 1px 1px 1px #000;


		> div {
			position: relative;

			margin-bottom: 30px;
		}

		h2 {
			margin: 0.25em 0;

			font-size: 1rem;
			text-align: center;

			color: #805D2F;
		}

		h3 {
			margin: 0.75em 0 0.25em 0;

			font-size: 0.8rem;
			text-decoration: underline;

			color: #715637;
		}

		p {
			margin: 0.5em 0;
			padding-left: 20px;

			font-size: 0.75rem;
			line-height: 1.1;

			color: #DAA969;


			b {
				font-weight: normal;

				color: #FFAD44;
			}

			u {
				text-decoration: none;

				color: #FF7144;
			}

			strong {
				font-size: 0.75rem;
				font-weight: normal;

				color: #FF9105;
			}

			em {
				color: #FFC271;
			}

			span {
				font-size: 0.7rem;
				font-weight: normal;
				color: #C18F4E;
			}


			.side-0 {
				color: #8171FF;
			}

			.side-1 {
				color: #D071FF;
			}
		}
	}

	&__fast-forward {
		position: absolute;
		top: 0;
		right: 20px;

		font-size: 20px;

		color: #8A6338;

		cursor: pointer;


		&:hover {
			color: #F19835;
		}
	}



	// adjust fading shadows
	.m-scroll-list {
		&:before {
			background: linear-gradient(#000, rgba(0,0,0,0));
		}

		&:after {
			height: 30px;

			background: linear-gradient(rgba(#241910,0), #291D12);
		}


		&__container {
			padding: 10px 30px 10px 10px;
		}
	}



	@include for-phone-landscape-up {
		margin: 30px 20px 50px 100px;
	}

	@include for-desktop-up {
		margin-right: 270px;
	}
}
