.pm-news {
	position: relative;

	display: flex;
	flex-flow: row nowrap;

	min-height: 500px;


	&__list {
		position: relative;

		flex: 0 0 445px;

		width: 445px;
	}

	&__change-log {
		flex: 0 1 auto;

		padding: 20px 5px 20px 30px;
	}
}





/**
 * News list column container.
 */
.pm-news-list {
	position: relative;

	width: 100%;
	height: 450px;
	margin-left: -10px;


	ul {
		list-style: none;
	}

	li {
		margin-bottom: 40px;
		text-shadow: 1px 1px 1px #000;

		&:before {
			@extend %font-nm;

			content: '\e946';

			float: left;
			margin: -8px 10px 0 -50px;

			font-size: 40px;

			color: #ad874d;
		}
	}

	&__title {
		font-size: 1rem;
		line-height: 1.1;

		color: #d6a346;
	}

	&__date {
		font-size: 0.65rem;

		color: #ad874d;
	}

	&__text {
		font-size: 0.75rem;
		line-height: 1.2;

		color: #d8b580;


		p {
			margin: 0.5em 0;
		}

		a {
			color: #d8b580;

			text-decoration: underline;
		}
	}
}





/**
 * Background for news list.
 */
.pm-news-list-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 450px;

	background: rgba(#231909, 0.5);



	@include for-phone-landscape-up {
		left: 80px;
	}
}





/**
 * Change log column container.
 */
.pm-news-change-log {
	margin-top: 20px;
}