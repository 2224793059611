.pm-notes {
	position: relative;

	display: flex;
	flex-flow: row nowrap;

	min-height: 500px;


	&__list {
		position: relative;

		flex: 0 0 445px;

		width: 445px;
	}

	&__info {
		flex: 0 1 auto;

		padding: 20px 5px 20px 30px;
	}
}





/**
 * Recipes list column container.
 */
.pm-recipes-list {
	position: relative;

	width: 100%;
	height: 450px;
	margin-left: -10px;


	// module
	&.m-scroll-list {
		&:before,
		&:after {
			background: linear-gradient(#231909, rgba(#231909,0));
		}

		&:after {
			background: linear-gradient(rgba(#231909,0), #231909);
		}


		&__container {
			padding-right: 10px;
			padding-left: 10px;
		}
	}


	// page
	.p-forge-crafting {
		margin-bottom: 0;


		&__outer {
			margin-top: 5px;
		}
	}
}





/**
 * Background for recipes list.
 */
.pm-notes-list-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	width: 450px;

	background-color: #231909;



	@include for-phone-landscape-up {
		left: 80px;
	}
}